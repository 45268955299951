@import 'variable';
@import 'mixin';
/* ======================================================================
 p_top
====================================================================== */
p+p {
  margin: 0;
  @include pc {
    margin: 0;
  }
}

p {
  line-height: 1.5;
  @include pc {
    line-height: 1.5;
  }
}

*:focus {
  outline: none;
}

._relative {
  position: relative;
}

._pointer-none {
  pointer-events: none;
}

._pointer-auto {
  pointer-events: auto;
}

.pd {
  @include sp_tab {
    padding-left: 4%;
    padding-right: 4%;
  }
}

._p0 {
  padding: 0 !important;
}

.l_main_visual_top {
  width: 100%;
  height: 695px;
  position: relative;
  //overflow: hidden;
  z-index: 500;
  background: $color_8;
  margin-bottom: 110px;
  @include sp_tab {
    height: 410px;
    //height: calc(var(--vh) *100);
    padding-top: 100px;
    margin-bottom: 185px;
  }

  .slick-list,
  .slick-slide,
  .slick-track {
    height: 100%;
  }

  .wave {
    position: absolute;
    bottom: -3px;
    width: 100%;
    z-index: 1;
    @include sp_tab {
      bottom: -102px;
    }

    &__img {
      width: 100%;
      min-width: 542px;
      height: auto;
      @include pc {
        height: 30px;
      }
      @include tab {
        height: 20px;
      }
      @include sp {
        height: 13px;
      }
    }
  }

  .mv-anim {
    &__img {
      &--g {
        width: 103px;
        @include sp_tab {
          width: 33px;
        }
      }

      &--b {
        width: 82px;
        @include sp_tab {
          width: 33px;
        }
      }

      &--y {
        width: 88px;
        @include sp_tab {
          width: 33px;
        }
      }

      &--lg {
        width: 102px;
        @include sp_tab {
          width: 33px;
        }
      }
    }
  }

  .slider_mv {
    &__inner {
      width: 100%;
      height: 100%;
      position: absolute;
    }

    .img {
      width: 100%;
      height: 100%;
      @include pc {
        height: 695px;
      }

      &.lazyloaded._pt1 {
        background: url("../img/p_top/sp/mv_1.webp") no-repeat bottom center/cover;
        @include pc_tab {
          background: url("../img/p_top/mv_1.webp") no-repeat center center/cover;
        }
      }

      &.lazyloaded._pt2 {
        background: url("../img/p_top/sp/mv_2.jpg") no-repeat center center/cover;
        @include pc_tab {
          background: url("../img/p_top/mv_2.jpg") no-repeat center center/cover;
        }
      }

      &.lazyloaded._pt3 {
        background: url("../img/p_top/sp/mv_3.jpg") no-repeat center center/cover;
        @include pc_tab {
          background: url("../img/p_top/mv_3.jpg") no-repeat center center/cover;
        }
      }

      &.lazyloaded._pt4 {
        background: url("../img/p_top/sp/mv_4.jpg") no-repeat center center/cover;
        @include pc_tab {
          background: url("../img/p_top/mv_4.jpg") no-repeat center center/cover;
        }
      }

      &.lazyloaded._pt5 {
        background: url("../img/p_top/sp/mv_5.jpg") no-repeat center center/cover;
        @include pc_tab {
          background: url("../img/p_top/mv_5.jpg") no-repeat center center/cover;
        }
      }
    }

    .slide_dots {
      position: absolute;
      z-index: 2;
      bottom: 16%;
      display: flex;
      flex-wrap: nowrap;
      left: 50%;
      transform: translateX(-50%);
      @include pc {
        transform: translate(calc(-50% - 5px), 5px);
        left: 50%;
        bottom: 10px;
      }

      li {
        display: inline-block;
        margin: 0 11px;

        button {
          position: relative;
          text-indent: -9999px;

          &:before {
            content: '';
            font-size: 20px;
            text-indent: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 10px;
            height: 10px;
            background: $gray_e6;
            border-radius: 50%;
            border: solid 1px $gray_d5;
            @include pc {
              width: 13px;
              height: 13px;
            }
          }
        }

        &.slick-active {
          button:before {
            content: '';
            background: $color_2;
            border-radius: 50%;
            border: solid 1px $color_4;
          }
        }
      }
    }
  }

  #wrap {
    > .inner {
      position: relative;
      z-index: 100;
      height: 100%;
      z-index: 1;
    }
  }

  .mv_txt {
    padding: 100px 4% 0;
    @include pc {
      padding: 160px 4% 0;
    }

    &__inner {}
  }

  .news-box {
    padding: 0 10px;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: -145px;
    left: 50%;
    transform: translateX(-50%);
    @include pc {
      bottom: -40px;
    }
    @include sp_tab {
      width: 100%;
    }

    &__left {
      padding: 8px 0;
      background: $white;
      border-radius: 30px 0 0 30px;
      display: flex;
      align-items: center;
      height: 100%;
      @include pc {
        width: 650px;
      }
    }

    &__inner {
      padding: 0 15px;
      overflow-x: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
      display: flex;
      align-items: center;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &__txt-wrap {
      margin: 0 10px 0 0;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      @include pc {
        font-size: 1.2rem;
      }
    }

    &__new {
      color: $color_2;
      font-size: 1em;
      line-height: 1.25;
      @include sp_tab {
        margin: 0 5px 0 0;
      }
      @include pc {
        margin: 0 10px 0 0;
        font-size: 1.2em;
      }
    }

    &__date {
      color: $gray_b2;
      font-size: 1em;
      line-height: 1.25;
      margin: 0;
      @include pc {
        font-size: 1.2em;
      }
    }

    &__info {
      width: 100%;
    }

    &__info-link {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    &__info-txt {
      font-size: 1.2rem;
      line-height: 1.3;
      white-space: nowrap;
      @include pc {
        font-size: 1.4rem;
      }
    }

    &__right {
      width: 75px;
      background: $color_7;
      border-radius: 0 30px 30px 0;
      @include pc {
        width: 145px;
      }
    }

    &__link-txt {
      width: 100%;
      height: 100%;
    }

    &__link {
      width: 100%;
      height: 100%;
      font-size: 1.2rem;
      color: $white;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.3s ease;

      &:hover {
        opacity: 0.7;
      }
      @include pc {
        font-size: 1.4rem;
      }

      &:after {
        content: url("../img/common/arrow.svg");
        margin: 0 0 0 5px;
      }
    }
  }

  .is-news {
    margin: 0 auto;
    width: 600px;
    text-align: left;
    position: relative;
    overflow: hidden;
    border: 1px solid $color_7;
    border-radius: 20px 0 0 20px;
    @include sp {
      width: 100%;
    }

    > ul {
      width: 100%;
      // height: 100%;
      position: relative;

      > li {
        width: 100%;
        // height: 100%;
        //display: none;
      }
    }
  }

  .info-box {
    position: relative;
    margin: -10px 0 0;
    @include pc {
      margin: 20px 0 0;
    }

    &__img {
      margin: 0 auto;
      @include pc {
        &.u_sptb {
          display: none!important;
        }
      }
      @include sp_tab {
        &.u_pc {
          display: none!important;
        }
      }
    }
  }

  .top-mv-txt {
    &__list {
      @include sp_tab {
        &:not(:first-child) {
          .top-mv-txt {
            &__inner {
              display: none;
            }
          }
        }
      }
    }

    &__inner {
      max-width: 800px;
      margin: 50% auto 0;
      padding: 20px 17px 30px;
      background: rgba($white, .8);
      border-radius: 6px;
      @media screen and (max-width: 428px) {
        margin: 50% auto 0;
      }
      @media screen and (max-width: 414px) {
        margin: 185px auto 0;
      }
      @media screen and (max-height: 580px) {
        margin: 140px auto 0;
        padding: 10px 8px;

        .top-mv-txt__ttl {
          font-size: 1.7em;
        }
      }
      // @include sp_tab {
      // margin-top: 28vh;
      // }
      @include pc {
        margin: 30px auto 0;
        padding: 40px;
        border-radius: 10px;
      }
    }

    &__ttl {
      font-size: 2.1em;
      line-height: 1.5;
      text-align: center;
      margin: 0;
      @include pc {
        font-size: 3.6em;
        margin: 0;
      }
    }

    &__min-txt {
      margin: 10px 0 0;
      font-size: 1.6em;
      line-height: 1.5;
      text-align: center;
      @include pc {
        margin: 20px 0 0;
        font-size: 2em;
      }
    }

    &__btn {
      max-width: 500px;
      margin: 20px auto 0;
      @include pc {
        margin: 30px auto 0;
      }

      .c_btn_1 {
        width: 100%;
        text-align: center;
      }
    }

    &__txt {
      margin: 0 auto;
      max-width: 100%;
      @include sp_tab {
        width: 336px;
      }

      &.u_sptb {
        display: block;
        @include pc {
          display: none;
        }
      }

      &.u_pc {
        display: none;
        @include pc {
          display: block;
        }
      }
    }
  }
}

#wrap {
  width: 100%;
  height: 100%;
}

.mv-anim {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
}
// #canvas {
//   position: absolute;
//   top: 0;
//   left: 0;
//   z-index: -1;
// }

.l_content {
  .l_main {}
}

.sec-1 {
  padding: 30px 4% 40px;
  background: $color_8;
  position: relative;
  @include pc {
    padding: 0;
  }
  // &:before {
  //   content: '';
  //   display: block;
  //   width: 35px;
  //   height: 42px;
  //   background: url(../img/common/leaf_g.png)no-repeat center center/contain;
  //   position: absolute;
  //   bottom: 24px;
  //   right: 10px;
  //   transform: rotate(30deg);
  //   @include pc {
  //     content: none;
  //   }
  // }
  .cont {
    //margin: 45px 0 0;
  }

  .slider-wrap {
    padding: 0 0 60px;
    @include pc {
      width: 1100px;
      margin: 0 auto;
    }
  }
}

.sec-2 {
  padding: 40px 4%;
  position: relative;
  @include sp_tab {
    background: $white;
  }
  @include sp_tab {
    margin-bottom: 30px;
  }
  // &:before {
  //   content: '';
  //   display: block;
  //   width: 31px;
  //   height: 38px;
  //   background: url(../img/common/leaf_b.png)no-repeat center center/contain;
  //   position: absolute;
  //   top: -10px;
  //   left: 5px;
  //   transform: rotate(-40deg);
  // }
  .btn-wrap {
    margin: 20px 0 0;
  }
}

.gmap {
  width: 100%;
  height: 310px;
  @include sp_tab {
    margin: 20px 0 0;
  }
  @include pc {
    height: 236px;
  }
}

.sec-3 {
  @include pc {
    //padding: 0 0 50px;
    background: $color_8;
    //background: linear-gradient($color_8 0%, $color_8 650px, #FDF9D8 650px, #FDF9D8 2510px, $color_8 2510px, $color_8 100%);
    margin-bottom: 70px;
  }
  @include sp_tab {
    margin-bottom: 40px;
  }

  &__inner {
    //background: linear-gradient(#FFFCF0, #FBF7C8);
    padding: 0;
    @include sp_tab {
      padding: 0;
    }
    @include pc {
      background: none;
    }
  }
}

.sec-4 {
  background: $color_8;

  &__inner {
    //padding: 40px 0;
    @include pc {
      //padding: 40px 0 100px;
    }
  }
}

.sec-5 {
  background: $color_8;
  padding: 0 0 40px;
  @include pc {
    padding: 0 0 640px;
  }

  &__inner {
    background: linear-gradient(-90deg, transparent 0%, transparent 20%, $color_15 20%, $color_15 100%);
    @include pc {
      background: none;
      position: relative;
      z-index: 1;

      &:before {
        content: '';
        display: block;
        width: calc(100% - 210px);
        height: 1010px;
        background: $color_15;
        position: absolute;
        top: 145px;
        right: 0;
        z-index: -1;
      }
    }
  }
}

.sec-6 {
  background: $color_8;
  padding: 0 0 40px;
  @include pc {
    padding: 0 0 580px;
  }

  &__inner {
    background: linear-gradient(90deg, transparent 0%, transparent 20%, $color_16 20%, $color_16 100%);
    @include pc {
      background: none;
      position: relative;
      z-index: 1;

      &:before {
        content: '';
        display: block;
        width: calc(100% - 210px);
        height: 1010px;
        background: $color_16;
        position: absolute;
        top: 145px;
        left: 0;
        z-index: -1;
      }
    }
  }
}

.sec-7 {
  background: url("../img/p_top/sp/sec-7_bg.png") no-repeat bottom center/cover;
  @include pc {
    background: url("../img/p_top/sec-7_bg.png") no-repeat bottom center/cover;
  }

  &__inner {
    position: relative;
    padding: 40px 4%;
    @include pc {
      width: 1100px;
      margin: 0 auto;
      padding: 100px 0 105px;
    }

    > .before {
      content: '';
      display: block;
      width: 37px;
      height: 45px;
      background: url(../img/common/leaf_lg.png)no-repeat center center/contain;
      position: absolute;
      bottom: 15px;
      left: 5px;
      transform: rotate(20deg);
      z-index: 2;
      @include pc {
        width: 72px;
        height: 88px;
        bottom: 175px;
        left: 355px;
      }
    }

    > .after {
      content: '';
      display: block;
      width: 30px;
      height: 37px;
      background: url(../img/common/leaf_g.png)no-repeat center center/contain;
      position: absolute;
      bottom: -5px;
      left: 58px;
      transform: rotate(-20deg);
      z-index: 2;
      @include pc {
        width: 59px;
        height: 76px;
        bottom: 97px;
        left: 460px;
      }
    }
  }
}

.sec-8 {
  background: $color_8;
  margin-bottom: 50px;
  @include sp_tab {
    margin-bottom: 30px;
  }

  &__inner {
    //padding: 40px 0;
    @include pc {
      //padding: 70px 0 65px;
    }
  }
}

.sec-9 {
  &__inner {
    padding: 40px 0;
    background: linear-gradient(#FFFCF0, #FBF7CC);
    @include pc {
      padding: 70px 0;
      background: linear-gradient(-90deg, #FFFCF0, #FBF7CC);
    }
  }

  &__banner-outer {
    margin: 20px 0 0;
    @include pc {
      margin: 50px 0 0;
    }
  }
}

.sec-10 {
  //background: $color_8;
  //padding: 40px 0 0;
  margin-bottom: 70px;
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: #FBF7C7;
    position: absolute;
    z-index: -1;
    @include sp_tab {
      width: 80%;
      height: 100%;
      right: 0;
    }
  }
  @include sp_tab {
    margin-bottom: 40px;
  }

  &__inner {
    @include pc {
      //padding: 40px 0 670px;
      z-index: 1;
    }
  }

  .btn01 {
    margin-bottom: 0;
    padding: 50px 0 70px;
    @include sp_tab {
      padding: 30px 0 50px;
    }
  }
}

.sec-11 {
  margin-bottom: 70px;
  @include sp_tab {
    margin-bottom: 40px;
  }

  &__inner {
    padding: 40px 0;
    background: #FFFCF0;
    @include pc {
      padding: 70px 0;
    }
  }
}

.sec-12 {
  &__inner {
    padding: 40px 0;
    @include pc {
      padding: 70px 0 90px;
    }
  }
}

.sec-13 {
  &__inner {
    padding: 40px 0;
    background: $color_8;
    @include pc {
      padding: 70px 0 100px;
    }
  }
}

.sec-14 {
  background: linear-gradient($color_8 0, $color_8 90px, $color_12 90px, $color_12 100%);
  margin-bottom: 50px;
  @include sp_tab {
    margin-bottom: 30px;
  }

  &__inner {
    padding: 20px 4% 40px;
    @include pc {
      width: 1100px;
      margin: 0 auto;
      padding: 0 0 50px;
    }
  }
}

.sec-15 {
  background: $color_8;

  &__inner {
    padding: 20px 4%;
    text-align: center;
    @include pc {
      width: 1100px;
      margin: 0 auto;
      padding: 50px 0;
    }
    @include pc_tab {
      display: flex;
      justify-content: space-between;
    }
  }
}

.sec-16 {
  background: linear-gradient(#FEFBEF, #FBF7C7);
  @include pc {
    background: $color_8;
  }
  margin-bottom: 50px;
  @include sp_tab {
    margin-bottom: 30px;
  }

  &__inner {
    padding: 0 4%;
    @include pc {
      width: 1100px;
      margin: 0 auto;
      //padding: 40px 0 70px;
    }
  }
}

.pc-sec-1 {
  margin-bottom: 50px;

  &.lazyloaded {
    background: url("../img/p_top/bg_cli.webp") no-repeat center center/cover;
  }

  &__inner {
    width: 1100px;
    margin: 0 auto;
    padding: 70px 0;
  }

  .c_ttl_1 {
    &__ttl {
      color: $white;
    }

    &__min-txt {
      color: $white;
    }
  }
}

.pc-sec-2 {
  background: $color_3;

  &__inner {
    width: 875px;
    margin: 0 auto;
    padding: 40px 0;
    color: $white;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.tel-box {
  &__txt-1 {
    font-size: 2.4em;
    line-height: 1.5;
    font-weight: 500;
  }

  &__txt-2 {
    font-size: 1.6em;
    line-height: 1.5;
    font-weight: 500;
  }

  &__txt-num {
    margin: 10px 0 0;
    font-size: 3.4em;
    line-height: 0.9;
    font-family: $font_2;
    color: $color_24;
    display: flex;
    align-items: center;

    &:before {
      content: url("../img/common/ico_tel.png");
    }
  }
}

.side-info {
  position: fixed;
  top: 210px;
  right: -2px;
  z-index: 1000;
  transition: 0.3s ease;

  &:hover {
    opacity: 0.7;
  }

  &__link {}
}

.side-info-left {
  position: fixed;
  top: 210px;
  left: -4px;
  z-index: 1000;
  transition: 0.3s ease;

  &:hover {
    opacity: 0.7;
  }

  &__link {}
}

.line-wrap {
  width: 400px;

  > a {
    display: block;
    transition: all 0.2s linear;

    &:hover {
      opacity: 0.8;
    }

    + p {
      font-size: 1.6em;
      text-align: center;
      margin: 3px 0 0;
    }
  }

  &__txt {
    font-size: 1.6em;
    line-height: 1.5;
    font-weight: 500;
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__txt {
    margin: 10px 7px 0 0;
    font-size: 1.6em;
    line-height: 1.5;
  }

  &__img {
    max-width: 100%;
    flex-shrink: 0;
  }
}

.zip {
  &__ttl {
    margin: 20px 0 0;
    font-size: 1.8em;
    line-height: 1.5;
    font-weight: bold;
    color: $white;
  }

  &__txt {
    margin: 10px 0 0;
    @include pc {
      color: $white;
    }
  }

  &__btn-wrap {
    @include pc {
      text-align: center;
      margin: 20px 0 0;
    }
  }
}

.info {
  margin: 30px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  &__left {
    flex-basis: calc(50% - 20px);
  }

  &__right {
    flex-basis: calc(50% - 20px);
  }

  &__ttl {
    margin: 0 0 20px;
    padding: 0 0 13px;
    position: relative;

    &:before {
      content: '';
      display: block;
      width: 60px;
      height: 2px;
      background: $white;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }

  &__ttl-txt {
    color: $white;
    text-align: center;
    font-size: 3em;
    font-weight: 500;
  }

  &__l-txt-wrap {
    margin: 20px 0 0;
    display: flex;
    justify-content: space-between;
  }

  &__l-txt {}

  %__info-txt {
    font-size: 1.6em;
    line-height: 1.5;
  }

  &__txt {
    @extend %__info-txt;
    color: $white;

    &--yellow {
      @extend %__info-txt;
      color: #FEDB0C;
    }
  }
}

.slider {
  max-width: 530px;
  margin: 0 auto;
  @include pc {
    width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .item {
    text-align: center;
    transition: 0.3s ease;
    margin-bottom: 20px;
    @include sp {
      margin-bottom: 0;
    }

    &:hover.nohover {
      cursor: default!important;

      a {
        cursor: default!important;
      }
    }

    &:hover:not(.nohover) {
      opacity: 0.7;
    }

    &:nth-child(3),
    &:nth-child(4) {
      //margin-bottom: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:nth-child(2n) {
      margin-left: 20px;
      @include sp {
        margin-left: 0;
      }
    }
    @include pc {
      flex-basis: calc(50% - 10px);
    }

    .link {
      display: inline-block;
      margin: 0 auto;

      .img {
        max-width: 100%;
        @include sp_tab {
          &.u_pc {
            display: none;
          }
        }
        @include pc {
          &.u_sptb {
            display: none;
          }
        }
      }
    }
  }

  .slide_dots {
    position: absolute;
    z-index: 1;
    left: 50%;
    bottom: -45px;
    transform: translateX(-50%);

    li {
      display: inline-block;
      margin: 0 10px;

      button {
        position: relative;
        text-indent: -9999px;

        &:before {
          content: '';
          font-size: 20px;
          text-indent: 0;
          position: absolute;
          top: 0;
          left: 0;
          width: 10px;
          height: 10px;
          background: $color_17;
          border-radius: 50%;
          // border: solid 1px $gray_d5;
        }
      }

      &.slick-active {
        button:before {
          content: '';
          background: $color_2;
          border-radius: 50%;
          // border: solid 1px $color_4;
        }
      }
    }
  }

  .slick-arrow {
    font-size: 0;
    position: absolute;
    bottom: -60px;
    @include pc {
      box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.03);
    }

    &.slick-prev {
      width: 40px;
      height: 40px;
      background: url("../img/common/prev.png") no-repeat center center/contain;
      left: 60px;
    }

    &.slick-next {
      width: 40px;
      height: 40px;
      background: url("../img/common/next.png") no-repeat center center/contain;
      right: 60px;
    }
  }
}

.calendar {
  @include sp_tab {
    margin: 20px 0 0;
  }

  &__inner {
    max-width: 450px;
    margin: 0 auto;
    @include pc {
      max-width: 528px;
    }
  }

  &__box {
    background: $white;
    border: solid 1px $color_10;
    @include pc {
      background: $white;
    }
  }

  &__weeks {
    color: $white;
    background: $color_2;
    height: 30px;
    display: flex;
    font-size: 1.4em;
    font-weight: bold;
    @include pc {
      height: 46px;
    }
  }

  &__time {
    width: 100%;
    font-size: 1.4em;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    @include pc {
      font-size: 2.1em;
      font-weight: 500;
    }
  }

  &__sch {
    height: 30px;
    display: flex;
    @include pc {
      height: 46px;
    }

    &:not(:last-child) {
      border-bottom: solid 1px $color_10;
    }
  }

  &__plan {
    width: 30px;
    font-weight: 900;
    border-left: solid 1px $color_10;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    @include pc {
      width: 45px;
      font-weight: 500;
      font-size: 1.9em;
    }
  }

  &__txt-wrap {
    margin: 10px 0 0;
    font-size: 1.4em;
    line-height: 1.428;
    @include pc {
      margin: 0;
    }
  }

  &__txt {
    display: flex;
    align-items: flex-start;
    @include pc {
      color: $white;
    }
  }
}

.read-wrap {
  margin: 20px 0 0;
  padding: 0 4%;
  @include pc {
    width: 1100px;
    margin: 30px auto 0;
    padding: 0;
  }

  &__txt {
    @include pc {
      letter-spacing: 0.08em;
    }

    &--center {
      text-align: center;
      line-height: 2;
    }

    &:not(:first-child) {
      margin: 20px 0 0;
      @include pc {
        margin: 30px 0 0;
      }
    }

    &--bold {
      font-weight: bold;
      font-size: 2rem;
      letter-spacing: 0.12;
      text-align: center;
      line-height: 2;
    }
  }

  &__txt-2 {
    @include pc {
      line-height: 1.9;
      letter-spacing: 0.08;
    }
  }

  &__big {
    margin: 0 0 10px;
    color: $color_3;
    font-size: 2em;
    line-height: 1.4;
    font-weight: bold;
    text-align: center;
    @include pc {
      margin: 0 0 20px;
      font-size: 2.6em;
    }
  }
}

.exp-wrap {
  margin: 20px 0 0;
  padding: 0 4%;
  @include pc {
    padding: 0;
  }

  &__txt {
    @include pc {
      line-height: 1.875;
      letter-spacing: 0.08em;
    }

    &:not(:first-child) {
      margin: 10px 0 0;
    }
  }

  &--m0 {
    padding: 0 4%;
    @include pc {
      padding: 0;
    }
  }
}

.sliders-wrap {
  margin: 40px 0 0;
  position: relative;
  background: #FBF7C7;
  @include pc {
    margin: 70px 0 0;
  }
  // &:before {
  //   content: '';
  //   display: block;
  //   width: 36px;
  //   height: 44px;
  //   background: url(../img/common/leaf_y.png)no-repeat center center/contain;
  //   position: absolute;
  //   top: -20px;
  //   right: 10px;
  //   transform: rotate(-30deg);
  //   z-index: 1;
  //   @include pc {
  //     content: none;
  //   }
  // }
  // &:after {
  //   content: '';
  //   display: block;
  //   width: 43px;
  //   height: 453px;
  //   background: url(../img/common/leaf_lg.png)no-repeat center center/contain;
  //   position: absolute;
  //   bottom: -230px;
  //   left: 10px;
  //   transform: rotate(35deg);
  //   z-index: 1;
  //   @include pc {
  //     content: none;
  //   }
  // }
  .sliders-1 {
    @include pc {
      min-height: 660px;
    }

    .item {
      @include pc {
        text-align: center;
      }
      @include sp_tab {
        &:not(:first-child) {
          margin: 20px 0 0;
        }
      }

      .img {
        max-width: 100%;
        @include pc {
          transform: translateX(420px);
        }
      }

      &:nth-child(even) {
        .img {
          @include pc {
            transform: translateX(-420px);
          }
        }
      }
    }
  }

  .sliders-2 {
    // position: relative;
    // margin: -50px auto 0;
    // padding: 0 2% 0 4%;
    @include sp_tab {
      position: relative;
      z-index: 1;
      margin: -40px 0 0;
    }
    @include pc {
      width: 1100px;
      height: 660px;
      margin: 0 auto;
      padding: 0;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;

      &.u_sptb {
        display: none;
      }
    }

    .item {
      @include pc {
        position: relative;

        &:nth-child(odd) {
          @include pc {
            margin: 0 auto 0 0;
          }
        }

        &:nth-child(even) {
          @include pc {
            margin: 0 0 0 auto;
          }
        }

        &:nth-child(1) {
          > .before {
            content: '';
            display: block;
            width: 75px;
            height: 92px;
            background: url("../img/common/leaf_y.png") no-repeat center center/contain;
            position: absolute;
            top: -20px;
            right: 50px;
            transform: rotate(-35deg);
            z-index: 2;
          }

          > .after {
            content: '';
            display: block;
            width: 84px;
            height: 104px;
            background: url("../img/common/leaf_lg.png") no-repeat center center/contain;
            position: absolute;
            bottom: -20px;
            left: 25px;
            transform: rotate(30deg);
            z-index: 2;
          }
        }

        &:nth-child(2) {
          > .before {
            content: '';
            display: block;
            width: 81px;
            height: 100px;
            background: url("../img/common/leaf_y.png") no-repeat center center/contain;
            position: absolute;
            top: -30px;
            right: -25px;
            transform: rotate(25deg);
            z-index: 2;
          }

          > .after {
            content: '';
            display: block;
            width: 101px;
            height: 125px;
            background: url("../img/common/leaf_b.png") no-repeat center center/contain;
            position: absolute;
            bottom: -41px;
            left: 70px;
            transform: rotate(-18deg);
            z-index: 2;
          }
        }

        &:nth-child(3) {
          > .before {
            content: '';
            display: block;
            width: 103px;
            height: 126px;
            background: url("../img/common/leaf_g.png") no-repeat center center/contain;
            position: absolute;
            top: -25px;
            right: -25px;
            transform: rotate(25deg);
            z-index: 2;
          }

          > .after {
            content: '';
            display: block;
            width: 73px;
            height: 89px;
            background: url("../img/common/leaf_lg.png") no-repeat center center/contain;
            position: absolute;
            bottom: -21px;
            left: 65px;
            transform: rotate(-15deg);
            z-index: 2;
          }
        }
      }

      %__point {
        width: 83.5px;
        height: 83.5px;
        position: absolute;
        top: -2px;
        left: -5px;
        z-index: 1;
        @include pc {
          width: 145px;
          height: 145px;
        }
      }

      .point {
        &--pt1 {
          @extend %__point;
          background: url("../img/p_top/point1.png") no-repeat center center/contain;
        }

        &--pt2 {
          @extend %__point;
          background: url("../img/p_top/point2.png") no-repeat center center/contain;
        }

        &--pt3 {
          @extend %__point;
          background: url("../img/p_top/point3.png") no-repeat center center/contain;
        }

        &__inner {
          display: inline-block;
        }

        &__txt-wrap {
          margin: 22px 0 0 11px;
          color: $white;
          text-align: center;
          @include pc {
            margin: 37px 0 0 14px;
          }
        }

        &__txt {
          font-size: 1.2em;
          transform: rotate(-45deg);
          @include pc {
            font-size: 2.1em;
          }
        }

        &__num {
          font-size: 2.2em;
          margin: 0;
          @include pc {
            font-size: 3.9em;
          }
        }
      }

      .sliders-inner {
        padding: 2px 0 0 2px;
        // padding: 2px 2% 9px 2px;
        @include pc {
          padding: 5px 0 0 5px;
        }

        %__bubble {
          position: relative;
          display: inline-block;
          margin: 0 auto 9px;
          padding: 4px 25px;
          color: $white;
          text-align: center;
          @include pc {
            margin: 0 auto 15px;
          }

          &:before {
            content: "";
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
            border-bottom: 6px solid transparent;
            border-right: 6px solid transparent;
            border-left: 6px solid transparent;
            @include pc {
              font-size: 1.5em;
              left: 32px;
              border-bottom: 0 solid transparent;
              border-right: 19px solid transparent;
              border-left: 0 solid transparent;
            }
          }
        }

        &__wrap {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 30px 15px 25px;
          background: $white;
          position: relative;
          box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.1);
          @include pc {
            width: 600px;
            padding: 85px 50px 45px 80px;
            display: block;
          }
        }

        &__bubble {
          &--pt1 {
            @extend %__bubble;
            background: $color_9;

            &:before {
              border-top: 9px solid $color_9;
              @include pc {
                border-top: 15px solid $color_9;
              }
            }
          }

          &--pt2 {
            @extend %__bubble;
            background: $color_11;

            &:before {
              border-top: 9px solid $color_11;
              @include pc {
                border-top: 15px solid $color_11;
              }
            }
          }

          &--pt3 {
            @extend %__bubble;
            background: $color_4;

            &:before {
              border-top: 9px solid $color_4;
              @include pc {
                border-top: 15px solid $color_4;
              }
            }
          }
        }

        &__txt {
          margin: 15px 0 0;
          @include pc {
            letter-spacing: 0.08em;
          }
        }

        &__btn-wrap {
          width: 100%;
          margin: 20px 0 0;
          @include pc {
            text-align: right;
          }
        }
      }
    }

    .slide_dots {
      position: absolute;
      z-index: 1;
      left: 50%;
      bottom: -45px;
      transform: translateX(-60%);

      li {
        display: inline-block;
        margin: 0 10px;

        button {
          position: relative;
          text-indent: -9999px;

          &:before {
            content: '';
            font-size: 20px;
            text-indent: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 10px;
            height: 10px;
            background: $color_17;
            border-radius: 50%;
            // border: solid 1px $gray_d5;
          }
        }

        &.slick-active {
          button:before {
            content: '';
            background: $color_2;
            border-radius: 50%;
            // border: solid 1px $color_4;
          }
        }
      }
    }

    .slick-arrow {
      font-size: 0;
      position: absolute;
      bottom: -60px;
      @include pc {
        box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.03);
      }

      &.slick-prev {
        width: 40px;
        height: 40px;
        background: url("../img/common/prev.png") no-repeat center center/contain;
        left: 100px;
      }

      &.slick-next {
        width: 40px;
        height: 40px;
        background: url("../img/common/next.png") no-repeat center center/contain;
        right: 100px;
      }
    }
  }
}

%__slider-noarrow-item {
  padding: 0 0 10px;
  @include pc {
    width: 260px;
    height: 226px;

    &:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)):not(:nth-child(4)) {
      margin: 10px 0 0;
    }
  }
}

.slider-noarrow {
  margin: 30px 0 0;
  @include sp_tab {
    margin-right: calc(50% - 50vw);
    margin-left: calc(50% - 50vw);
  }
  @include pc {
    margin: 100px 0 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__slider {
    @include pc {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      &:before {
        content: "";
        display: block;
        width: 260px;
        height: 0;
        order: 1;
      }

      &:after {
        content: "";
        display: block;
        width: 260px;
        height: 0;
      }
    }
  }

  &__item {
    @extend %__slider-noarrow-item;

    &--big {
      @extend %__slider-noarrow-item;
      @include pc {
        width: 350px;
        height: 260px;

        &:nth-child(4) {
          margin: 10px 0 0;
        }
      }
    }
  }

  &__link {
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    display: block;
    overflow: hidden;

    &:hover {
      .slider-noarrow {
        &__txt {
          background: $color_4;

          &:after {
            transform: translateX(10px);
          }
        }

        &__img {
          transform: scale(1.06);
        }
      }
    }
  }

  &__img {
    width: 100%;
    object-fit: cover;
    font-family: 'object-fit: cover;';
    transition: 0.3s ease;
  }

  &__img-wrap {
    width: 100%;
  }

  &__txt {
    background: $color_2;
    padding: 15px 10px 15px 15px;
    color: $white;
    border-radius: 0 0 10px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.5em;
    @include sp_tab {
      align-items: flex-start;
    }
    @include pc {
      font-size: 1.6em;
      font-weight: 500;
      transition: 0.3s ease;
    }

    &:after {
      content: url("../img/common/arrow_white.png");
      @include pc {
        transform: translate(0);
        transition: 0.3s ease;
      }
    }
  }

  .slick-slide {
    margin: 0 5px;
  }

  .slide_dots {
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    z-index: 1;
    left: 50%;
    bottom: -30px;
    transform: translateX(-50%);

    li {
      display: inline-block;
      margin: 0 10px;

      button {
        position: relative;
        text-indent: -9999px;

        &:before {
          content: '';
          font-size: 20px;
          text-indent: 0;
          position: absolute;
          top: 0;
          left: 0;
          width: 10px;
          height: 10px;
          background: $color_17;
          border-radius: 50%;
          // border: solid 1px $gray_d5;
        }
      }

      &.slick-active {
        button:before {
          content: '';
          background: $color_2;
          border-radius: 50%;
          // border: solid 1px $color_4;
        }
      }
    }
  }
}

.slider-3 {
  margin: 30px 0 0;
  @include pc {
    width: 1100px;
    margin: 30px auto 0;
  }

  &__item {}

  &__link {
    display: block;
  }

  &__img {
    max-width: 100%;
    object-fit: cover;
    font-family: 'object-fit: cover;';
  }

  &__img-wrap {
    width: 100%;
  }

  &__txt {
    background: #F3F4F1;
    // min-height: 64px;
    padding: 15px;
    font-size: 1.5em;
  }

  .slick-slide {
    margin: 0 5px;
    @include pc {
      margin: 0 21px;
    }
  }

  .slide_dots {
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    z-index: 1;
    left: 50%;
    bottom: -45px;
    transform: translateX(-50%);
    @include pc {
      bottom: -65px;
    }

    li {
      display: inline-block;
      margin: 0 10px;

      button {
        position: relative;
        text-indent: -9999px;

        &:before {
          content: '';
          font-size: 20px;
          text-indent: 0;
          position: absolute;
          top: 0;
          left: 0;
          width: 10px;
          height: 10px;
          background: $color_17;
          border-radius: 50%;
        }
      }

      &.slick-active {
        button:before {
          content: '';
          background: $color_2;
          border-radius: 50%;
        }
      }
    }
  }

  .slick-arrow {
    font-size: 0;
    position: absolute;
    bottom: -60px;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.03);
    z-index: 2;
    @include pc {
      bottom: -90px;
      box-shadow: none;
    }

    &.slick-next,
    &.slick-prev {
      width: 40px;
      height: 40px;
      @include pc {
        width: 66px;
        height: 66px;
      }
    }

    &.slick-prev {
      background: url("../img/common/sp/prev.png") no-repeat center center/contain;
      left: 4%;
      @include pc {
        background: url("../img/common/prev.png") no-repeat center center/contain;
        left: 300px;
      }
    }

    &.slick-next {
      background: url("../img/common/sp/next.png") no-repeat center center/contain;
      right: 4%;
      @include pc {
        background: url("../img/common/next.png") no-repeat center center/contain;
        right: 300px;
      }
    }
  }
}

.slider-4 {
  margin: 30px 0 0;
  @include pc {
    width: 1100px;
    margin: 30px auto 0;
    position: relative;

    > .before {
      content: '';
      display: block;
      background: url(../img/common/leaf_lg.png)no-repeat center center/contain;
      width: 117px;
      height: 144px;
      position: absolute;
      top: -185px;
      left: -170px;
      transform: rotate(25deg);
    }

    > .after {
      content: '';
      display: block;
      background: url(../img/common/leaf_y.png)no-repeat center center/contain;
      width: 88px;
      height: 132px;
      position: absolute;
      top: -350px;
      right: -160px;
      transform: rotate(-30deg);
    }

    > .before2 {
      content: '';
      display: block;
      background: url(../img/common/leaf_b.png)no-repeat center center/contain;
      width: 51px;
      height: 62px;
      position: absolute;
      bottom: -5px;
      left: -50px;
      transform: rotate(10deg);
    }

    > .after2 {
      content: '';
      display: block;
      background: url(../img/common/leaf_g.png)no-repeat center center/contain;
      width: 84px;
      height: 103px;
      position: absolute;
      bottom: -20px;
      right: -130px;
      transform: rotate(-20deg);
    }
  }

  &__slider {
    @include pc {
      position: relative;
    }
  }

  &__item {
    padding: 0 0 10px;
  }

  &__link {
    background: #FFF;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
    display: block;

    &:hover {
      .c_btn_1--brown {
        background: $color_28;
      }

      .slider-4 {
        &__img {
          transform: scale(1.06);
        }
      }
    }
  }

  &__img {
    max-width: 100%;
    object-fit: cover;
    font-family: 'object-fit: cover;';
    margin: 0 auto;
    transition: 0.3s ease;
  }

  &__img-wrap {
    width: 100%;
    overflow: hidden;
  }

  &__tag {}

  &__txt-wrap {
    padding: 15px 15px 25px;
    border-radius: 0 0 5px 5px;
    @include pc {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }
  }

  &__txt {
    margin: 10px 0 35px;
    font-size: 1.6em;
    line-height: 1.5;
    letter-spacing: 0.08em;
    font-weight: bold;
  }

  &__btn {
    @include pc {}
  }

  .slick-slide {
    margin: 0 5px;
    @include pc {
      padding: 0 3px 10px 0;
    }
  }

  .slide_dots {
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    z-index: 1;
    left: 50%;
    bottom: -45px;
    transform: translateX(-50%);
    @include pc {
      bottom: -60px;
    }

    li {
      display: inline-block;
      margin: 0 10px;

      button {
        position: relative;
        text-indent: -9999px;

        &:before {
          content: '';
          font-size: 20px;
          text-indent: 0;
          position: absolute;
          top: 0;
          left: 0;
          width: 10px;
          height: 10px;
          background: $color_17;
          border-radius: 50%;
        }
      }

      &.slick-active {
        button:before {
          content: '';
          background: $color_2;
          border-radius: 50%;
        }
      }
    }
  }

  .slick-arrow {
    font-size: 0;
    position: absolute;
    bottom: -60px;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.03);
    z-index: 2;
    @include pc {
      box-shadow: none;
      bottom: -90px;
    }

    &.slick-next,
    &.slick-prev {
      width: 40px;
      height: 40px;
      @include pc {
        width: 66px;
        height: 66px;
      }
    }

    &.slick-prev {
      background: url("../img/common/sp/prev.png") no-repeat center center/contain;
      left: 4%;
      @include pc {
        background: url("../img/common/prev.png") no-repeat center center/contain;
        left: 300px;
      }
    }

    &.slick-next {
      background: url("../img/common/sp/next.png") no-repeat center center/contain;
      right: 4%;
      @include pc {
        background: url("../img/common/next.png") no-repeat center center/contain;
        right: 300px;
      }
    }
  }
}

.slider-btn-wrap {
  margin: 80px 0 0;
  padding: 0 4%;
  @include pc {
    margin: 140px 0 0;
    padding: 0;
    text-align: center;
  }
}

.banner-wrap {
  padding: 20px 4% 0;
  background: $color_8;
  text-align: center;
  @include pc {
    width: 1100px;
    margin: 0 auto;
    padding: 0;
    display: flex;
    justify-content: space-between;
  }

  &__link {
    display: inline-block;
    flex-basis: calc(50% - 10px);
    transition: 0.3s ease;

    &:hover {
      opacity: 0.7;
    }

    &:not(:first-child) {
      margin: 10px 0 0;
      @include pc {
        margin: 0;
      }
    }
  }

  &__img {
    max-width: 100%;
  }
}

.doctor-slider {
  margin: 20px 0 0;
  padding: 0 0 80px;
  background: linear-gradient(90deg, transparent 0%, transparent 20%, $color_14 20%, $color_14 100%);
  @include pc {
    width: 1150px;
    margin: 30px auto 0;
    padding: 50px 0 0;
    transform: translateX(25px);
    background: linear-gradient(90deg, transparent 0%, transparent 322px, $color_14 322px, $color_14 100%);
    position: relative;

    > .before {
      content: '';
      display: block;
      width: 108px;
      height: 132px;
      background: url("../img/common/leaf_y.png") no-repeat center center/contain;
      position: absolute;
      top: -80px;
      right: -80px;
      transform: rotate(-28deg);
      z-index: 2;
    }

    > .after {
      content: '';
      display: block;
      width: 84px;
      height: 103px;
      background: url("../img/common/leaf_g.png") no-repeat center center/contain;
      position: absolute;
      bottom: -45px;
      right: -45px;
      transform: rotate(-15deg);
      z-index: 2;
    }

    > .before2 {
      content: '';
      display: block;
      width: 117px;
      height: 144px;
      background: url("../img/common/leaf_lg.png") no-repeat center center/contain;
      position: absolute;
      top: 40px;
      left: -200px;
      transform: rotate(20deg);
      z-index: 2;
    }

    > .after2 {
      content: '';
      display: block;
      width: 51px;
      height: 62px;
      background: url("../img/common/leaf_b.png") no-repeat center center/contain;
      position: absolute;
      bottom: -35px;
      left: -65px;
      transform: rotate(10deg);
      z-index: 2;
    }
  }

  &__slider {
    position: relative;
  }

  .item {
    .doctor-inner {
      @include pc {
        height: 600px;
        position: relative;
      }

      &__img-wrap {}

      &__img {
        max-width: 100%;
        margin: 0 auto;
        @include pc {
          margin: 0;
        }
      }

      &__cont {
        padding: 20px 4% 0;
        @include sp_tab {
          position: relative;
          margin: -90px 0 0;
          z-index: 10;
        }
        @include pc {
          width: 650px;
          padding: 0;
          position: absolute;
          bottom: 50px;
          right: 50px;
        }
      }

      &__inner {
        padding: 20px 15px 25px;
        background: $white;
        @include pc {
          padding: 40px 50px;
        }
      }

      &__ttl {}

      &__name-wrap {
        display: flex;
        align-items: center;
      }

      &__name {
        margin: 0 20px 0 0;
        font-size: 2em;
        line-height: 2;
        color: $color_7;
        @include pc {
          font-size: 3em;
          margin: 0 30px 0 0;
        }
      }

      &__tag {
        display: inline-block;
        padding: 0 20px;
        font-size: 1.3em;
        line-height: 1.6;
        color: $color_13;
        border: solid 1px $color_13;
        border-radius: 3px;
      }

      &__en {
        font-size: 1.4em;
        color: $color_2;
      }

      &__btn-wrap {
        margin: 20px 0 0;
        @include pc {
          text-align: right;
        }
      }

      ._red {
        @include pc {
          font-weight: bold;
        }
      }
    }
  }

  .slide_dots {
    position: absolute;
    z-index: 1;
    left: 50%;
    bottom: -45px;
    transform: translateX(-55%);
    @include pc {
      bottom: -55px;
    }

    li {
      display: inline-block;
      margin: 0 10px;

      button {
        position: relative;
        text-indent: -9999px;

        &:before {
          content: '';
          font-size: 20px;
          text-indent: 0;
          position: absolute;
          top: 0;
          left: 0;
          width: 10px;
          height: 10px;
          background: $color_17;
          border-radius: 50%;
        }
      }

      &.slick-active {
        button:before {
          content: '';
          background: $color_2;
          border-radius: 50%;
        }
      }
    }
  }

  .slick-arrow {
    font-size: 0;
    position: absolute;
    bottom: -60px;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
    z-index: 1;
    @include pc {
      bottom: 50px;
      box-shadow: none;
    }

    &.slick-next,
    &.slick-prev {
      width: 40px;
      height: 40px;
      @include pc {
        width: 66px;
        height: 66px;
      }
    }

    &.slick-prev {
      background: url("../img/common/sp/prev.png") no-repeat center center/contain;
      left: calc(50% - 90px);
      @include pc {
        background: url("../img/common/prev.png") no-repeat center center/contain;
        left: 0;
      }
    }

    &.slick-next {
      background: url("../img/common/sp/next.png") no-repeat center center/contain;
      right: calc(50% - 90px);
      @include pc {
        background: url("../img/common/next.png") no-repeat center center/contain;
        left: 85px;
      }
    }
  }
}

.is-slick-counter {
  position: absolute;
  top: 20px;
  right: 50px;
  font-size: 3.4em;
  color: $white;
  font-weight: 500;
  font-family: $font_2;
  letter-spacing: 0.15em;
  display: flex;
  align-items: center;

  .sla {
    font-size: 2.6rem;
  }
}

.head-img {
  @include pc {
    text-align: center;
    transform: translateX(-425px);
  }

  &--left {
    @include pc {
      // text-align: right;
      text-align: center;
      transform: translateX(425px);
    }
  }

  &__img {
    max-width: 100%;
  }
}

%__ins-cont {
  position: relative;
  padding: 0 4% 50px;
  position: relative;

  > .before {
    content: '';
    display: block;
    width: 39px;
    height: 48px;
    position: absolute;
    top: -15px;
    left: 3px;
    transform: rotate(-40deg);
  }

  > .after {
    content: '';
    display: block;
    width: 30px;
    height: 37px;
    position: absolute;
    bottom: 385px;
    right: 5px;
    transform: rotate(30deg);
    @include pc {
      bottom: 325px;
    }
  }
}

%__ins-inner {
  background: $white;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px 0 0;
  @include pc {
    padding: 50px;
  }
}

.ins-cont {
  &--pt1 {
    @extend %__ins-cont;
    margin: -60px 0 0;
    @include pc {
      margin: 0;
      padding: 0;
      width: 1100px;
      position: absolute;
      top: 40px;
      left: 0;
      right: 0;
      margin: 0 auto;
    }

    > .before {
      background: url(../img/common/leaf_y.png)no-repeat center center/contain;
      @include pc {
        width: 108px;
        height: 132px;
        top: 120px;
        left: -302px;
        transform: rotate(25deg);
      }
    }

    > .after {
      background: url(../img/common/leaf_y.png)no-repeat center center/contain;
      @include pc {
        width: 88px;
        height: 108px;
        top: 420px;
        left: -230px;
        transform: rotate(-5deg);
      }
    }
  }

  &__btn-wrap {
    margin: 20px 0 0;
    padding: 0 15px 25px;
    @include pc {
      padding: 0;
      text-align: right;
    }
  }

  &--pt2 {
    //@extend %__ins-cont;
    //margin: -60px 0 0;
    @include pc {
      margin: 0;
      padding: 0;
      width: 1100px;
      position: absolute;
      top: 40px;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
    @include sp_tab {
      margin: -60px 15px 0;
      z-index: 1;
      position: relative;
    }

    > .before {
      background: url(../img/common/leaf_b.png)no-repeat center center/contain;
      @include pc {
        width: 99px;
        height: 121px;
        top: 150px;
        right: -250px;
        left: auto;
        transform: rotate(-15deg);
      }
    }

    > .after {
      background: url(../img/common/leaf_b.png)no-repeat center center/contain;
      @include pc {
        width: 82px;
        height: 101px;
        top: 460px;
        right: -190px;
        left: auto;
        transform: rotate(15deg);
      }
    }
  }

  &--pt3 {
    @extend %__ins-cont;
    margin: 20px 0 0;
    @include pc {
      margin: 60px 0 0;
      padding: 0;
    }
  }

  &__inner {
    @extend %__ins-inner;
    @include pc {
      width: 650px;
    }

    &--pt2 {
      @extend %__ins-inner;
      @include pc {
        width: 650px;
        margin: 10px 0 0 auto;
      }
    }

    &--pt3 {
      @extend %__ins-inner;
      padding-top: 20px;
      @include pc {
        width: 710px;
        padding: 50px 28px 50px 170px;
        margin: 0 0 0 auto;
      }
    }
  }
}

.mov-youtube {
  margin: 30px 0 0;
  text-align: center;
  display: none;
  width: 100%;
  @include pc {
    height: 298px;
    width: 532px;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 280px;
    left: 0;
    z-index: 1;
  }
}

.mov-wrap {
  margin: 30px 0 0;
  text-align: center;
  cursor: pointer;
  @include pc {
    margin: 0;
    padding: 0;
    position: absolute;
    top: 280px;
    left: 0;
    z-index: 1;
  }

  &__img {
    max-width: 100%;
  }
}

.tab-index {
  margin: 20px 0 0;
  @include pc {
    margin: 30px 0 0;
  }

  &__inner {
    padding: 0 4%;
    display: flex;
    align-items: flex-end;
    @include pc {
      width: 1100px;
      margin: 0 auto;
      padding: 0;
    }
  }

  %__tab-index-txt {
    border-radius: 5px 5px 0 0;
    flex: 1;
    transition: 0.3s ease;

    &:hover {
      opacity: 0.7;
    }

    &:not(:first-child) {
      margin: 0 0 0 7px;
    }
  }

  &__txt {
    &--pt1 {
      @extend %__tab-index-txt;
      background: $color_18;
    }

    &--pt2 {
      @extend %__tab-index-txt;
      background: $color_19;
    }

    &--pt3 {
      @extend %__tab-index-txt;
      background: $color_20;
    }

    &--pt4 {
      @extend %__tab-index-txt;
      background: $color_21;
    }
  }

  &__link {
    display: block;
    padding: 20px 0;
    text-align: center;
    font-size: 1.4rem;
    @include pc {
      font-size: 1.6rem;
      padding: 10px 0;
    }

    &._active {
      padding: 25px 0 20px;
      font-weight: bold;
      @include pc {
        padding: 15px 0;
      }
    }
  }

  .tab-index__link {
    color: $white;
  }
}

.tab-cont {
  padding: 0 4%;
  @include pc {
    width: 1100px;
    margin: 0 auto;
    padding: 0;
    position: relative;

    > .before {
      content: '';
      display: block;
      background: url(../img/common/leaf_lg.png)no-repeat center center/contain;
      width: 117px;
      height: 144px;
      position: absolute;
      top: -65px;
      left: -200px;
      transform: rotate(25deg);
    }

    > .after {
      content: '';
      display: block;
      background: url(../img/common/leaf_y.png)no-repeat center center/contain;
      width: 88px;
      height: 108px;
      position: absolute;
      top: -230px;
      right: -180px;
      transform: rotate(-30deg);
    }
  }

  &__inner {
    padding: 10px;
    border: solid 1px $color_22;
    border-radius: 0 0 5px 5px;
    @include pc {
      padding: 30px;
      position: relative;

      > .before {
        content: '';
        display: block;
        background: url(../img/common/leaf_b.png)no-repeat center center/contain;
        width: 51px;
        height: 62px;
        position: absolute;
        bottom: -105px;
        left: -60px;
        transform: rotate(10deg);
      }

      > .after {
        content: '';
        display: block;
        background: url(../img/common/leaf_g.png)no-repeat center center/contain;
        width: 84px;
        height: 103px;
        position: absolute;
        bottom: -110px;
        right: -170px;
        transform: rotate(-20deg);
      }
    }
  }

  &__box {
    display: none;
  }

  &__item-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &:before {
      content: '';
      display: block;
      height: 0;
      order: 1;
      flex-basis: calc(50% - 3.5px);
      @include pc {
        flex-basis: calc(25% - 6.5px);
      }
    }

    &:after {
      content: '';
      display: block;
      height: 0;
      order: 1;
      flex-basis: calc(50% - 3.5px);
      @include pc {
        flex-basis: calc(25% - 6.5px);
      }
    }
  }

  &__item {
    height: 70px;
    background: $white;
    border: solid 1px $color_22;
    border-radius: 5px;
    flex-basis: calc(50% - 3.5px);
    @include pc {
      height: 50px;
      flex-basis: calc(25% - 6.5px);
    }

    &:not(:nth-child(1)):not(:nth-child(2)) {
      margin: 5px 0 0;
      @include pc {
        margin: 0;
      }
    }

    &:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)):not(:nth-child(4)) {
      @include pc {
        margin: 13px 0 0;
      }
    }
  }

  &__link {
    height: 100%;
    padding: 0 25px;
    display: block;
    font-size: 1.2em;
    line-height: 1.333;
    font-weight: bold;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &:hover {
      &:after {
        right: 0;
      }
    }
    @include pc {
      font-size: 1.4em;
      font-weight: bold;
    }

    &:after {
      content: url("../img/common/arrow2_brown.png");
      position: absolute;
      top: 50%;
      bottom: 0;
      right: 10px;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      @include pc {
        transition: 0.3s ease;
      }
    }
  }

  &__min {
    font-size: 1rem;
    @include pc {
      font-size: 1.2rem;
    }
  }

  &__btn {
    margin: 20px 0 0;
    @include pc {
      margin: 50px 0 0;
      text-align: center;
    }
  }

  &__txt {
    line-height: 1.25;
    font-size: 1.4rem;
  }
}

.data {
  margin: 20px 0 0;
  padding: 0 4%;
  @include pc {
    width: 1100px;
    margin: 30px auto 0;
    padding: 0;
    position: relative;

    .before {
      content: '';
      display: block;
      background: url(../img/common/leaf_lg.png)no-repeat center center/contain;
      width: 117px;
      height: 144px;
      position: absolute;
      top: -45px;
      left: -200px;
      transform: rotate(25deg);
    }

    .after {
      content: '';
      display: block;
      background: url(../img/common/leaf_y.png)no-repeat center center/contain;
      width: 88px;
      height: 108px;
      position: absolute;
      top: -230px;
      right: -180px;
      transform: rotate(-30deg);
    }
  }

  &__inner {
    padding: 30px 15px 25px;
    background: $white;
    border-radius: 5px;
    @include pc {
      padding: 50px 70px;
      position: relative;

      .before {
        content: '';
        display: block;
        background: url(../img/common/leaf_b.png)no-repeat center center/contain;
        width: 51px;
        height: 62px;
        position: absolute;
        bottom: 75px;
        left: -60px;
        transform: rotate(10deg);
      }

      .after {
        content: '';
        display: block;
        background: url(../img/common/leaf_g.png)no-repeat center center/contain;
        width: 84px;
        height: 103px;
        position: absolute;
        bottom: 70px;
        right: -150px;
        transform: rotate(-20deg);
      }
    }
  }

  &__box {
    display: none;
  }

  .circliful {
    position: relative;
  }

  .circle-info,
  .circle-info-half,
  .circle-text,
  .circle-text-half {
    width: 100%;
    position: absolute;
    text-align: center;
    display: inline-block;
  }

  &__item-list {
    margin: 20px 0 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  &__item {
    text-align: center;
    @include pc {
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    &:not(:first-child) {
      margin: 0 0 0 5px;
      @include pc {
        margin: 0 0 0 30px;
      }
    }
  }

  &__item-txt {
    font-size: 1.4em;
    font-weight: bold;
    @include pc {
      font-size: 1.8em;
      order: 1;
    }
  }

  &__item-box {}

  &__wrap {
    @include pc {
      display: flex;
      align-items: center;
      margin: 0 0 10px;
    }
  }

  &__item-num-wrap {
    font-size: 1.6em;
    line-height: 1.5;
    @include pc {
      font-size: 2em;
      margin: 0 15px 0 0;
    }
  }

  %__item-num {
    font-size: 2.6rem;
    @include pc {
      font-size: 5rem;
    }
  }

  &__item-num {
    &--pt1 {
      @extend %__item-num;
      color: $color_2;
    }

    &--pt2 {
      @extend %__item-num;
      color: $color_24;
    }

    &--pt3 {
      @extend %__item-num;
      color: $color_11;
    }
  }

  &__total {
    margin: 20px 0 0;
    text-align: center;
  }

  &__total-wrap {
    @include pc {
      display: flex;
      justify-content: center;
      align-items: flex-end;
    }
  }

  &__total-ttl {
    font-size: 1.6em;
    font-weight: bold;
    line-height: 1.5;
    @include pc {
      margin: 0 15px 10px 0;
      font-weight: normal;
    }
  }

  &__total-box {
    font-size: 2em;
  }

  &__total-num {
    font-size: 5rem;
    color: $color_2;
  }

  &__total-txt {
    margin: 10px 0 0;
    font-size: 1.4em;
  }

  &__btn {
    margin: 30px 0 0;
    @include pc {
      margin: 70px 0 0;
      text-align: center;
    }
  }
}

.tab-index2 {
  &__inner {
    display: flex;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
    @include pc {
      justify-content: center;
    }
  }

  &__txt {
    width: 90px;
    font-size: 1.4em;
    text-align: center;
    border: solid 1px $color_13;
    border-radius: 5px;
    flex-shrink: 0;

    &:not(:first-child) {
      margin: 0 0 0 5px;
    }

    &.is-current {
      width: 100px;
      background: $color_23;
      border: none;
    }
  }

  &__link {
    display: block;
    height: 100%;
    padding: 10px 0;

    &._active {
      color: $white;
      font-weight: bold;
    }
  }

  &__swipe {
    margin: 10px 0;
    justify-content: center;
    align-items: center;
  }

  &__swipe-txt {
    margin: 0 0 0 10px;
    font-size: 13px;
  }
}

.tab-index3 {
  &__inner {
    display: flex;
  }

  &__txt {
    width: 50%;
    background: $gray_f2;
    text-align: center;
    border-bottom: solid 1px $gray_e6;
    font-weight: 500;
    @include pc {
      border: none;
      background: $white;
      padding: 40px 50px 0;
      display: flex;
      align-items: center;
    }

    &:not(:first-child) {
      border-left: solid 1px $gray_e6;
      @include pc {
        border: none;
      }
    }

    &.is-current {
      background: $white;
      border-bottom: none;
    }
  }

  &__link {
    padding: 15px 0;
    display: block;
    font-size: 1.4rem;
    @include pc {
      padding: 0;
      font-size: 2rem;
      font-weight: 500;
      color: $color_7;
      pointer-events: none;
    }

    &._active {
      color: $color_7;
    }
  }

  &__en {
    margin: 0 0 0 20px;
    font-size: 1.3rem;
    color: $color_2;
    font-weight: bold;
    letter-spacing: 0.1;
    font-family: $font_2;
    align-items: center;

    &:before {
      content: '';
      display: block;
      width: 35px;
      height: 1px;
      margin: 0 20px 0 0;
      background: $gray_e6;
    }
  }
}

.cat-wrap {
  margin: 30px 0 0;
  padding: 0 4%;
  @include pc {
    padding: 0;
  }

  &__tag-wrap {
    margin: 12px 0 0;
    @include pc {
      margin: 30px 0 0;
      text-align: center;
    }
  }

  .c_tag_1 {
    @include pc {
      &:not(:first-child) {
        margin: 0 0 0 7px;
      }
    }
  }
}

.nr_05pt2__tag {
  margin-right: 0;
}

.cat-list {
  display: flex;
  flex-wrap: wrap;
}

.news {
  background: $white;
  padding: 10px 15px 30px;
  box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.1);

  &__inner {
    @include pc {
      display: flex;
    }
  }

  &__wrap {
    display: none;
    @include pc {
      display: block;
      flex: 1;

      &:not(:first-child) {}
    }
  }

  %__news-item-list {
    @include pc {
      padding: 0 50px 50px;
    }
  }

  &__item-list {
    @extend %__news-item-list;

    &--line {
      @extend %__news-item-list;
      @include pc {
        // border-right: solid 1px $gray_e6;
      }
    }
  }

  &__item {
    border-bottom: solid 1px $gray_e6;
  }

  &__link {
    height: 100%;
    padding: 10px 0;
    display: block;

    &:hover {
      .news {
        &__txt {
          color: #AC835D;
          text-decoration: underline;
        }
      }
    }
  }

  &__date-cat {
    display: flex;
    align-items: flex-start;
  }

  &__date {
    margin: 0 10px 0 0;
    color: $gray_a2;
    font-size: 1.2em;
    font-weight: bold;
    flex-shrink: 0;
    @include pc {
      font-size: 1.3em;
      font-weight: bold;
    }
  }

  &__cat {
    color: $color_3;
    font-size: 1rem;
    font-weight: 500;

    &:before {
      content: url("../img/p_top/ico_file.png");
      margin: 0 4px 0 0;
    }
  }

  &__cont {
    margin: 5px 0 0;
    display: flex;
    align-items: center;
  }

  &__new {
    margin: 0 10px 0 0;
    color: $white;
    font-family: $font_2;
    font-size: 1rem;
    font-weight: 500;
    flex-shrink: 0;
    background: $color_2;
    border-radius: 4px;
    width: 50px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__txt {
    color: $color_3;
    font-size: 1.4em;
    line-height: 1.571;
    @include pc {
      font-size: 1.6em;
    }
  }

  &__btn {
    margin: 20px 0 0;
    @include pc {
      text-align: center;
      margin: -30px 0 0;
      position: relative;
    }
  }

  &__line {
    width: 1px;
    background: linear-gradient($gray_e6 0%, $gray_e6 calc(100% - 15px), transparent calc(100% - 15px), transparent 100%);
  }
}

.is-slick-counter {
  display: none;
  @include pc {
    display: block;
  }
}

.banner {
  display: inline-block;
  @include pc_tab {
    flex-basis: calc(50% - 20px);
  }

  &:not(:first-child) {
    margin: 10px 0 0;
    @include pc_tab {
      margin: 0;
    }
  }

  &__img {
    max-width: 100%;
  }
}

.banner-list {
  text-align: center;
  @include pc_tab {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    text-align: center;
  }

  &__banner {
    flex-basis: calc(50% - 10px);
    display: block;
    height: 100%;

    &:not(:first-child) {
      margin: 10px 0 0;
      @include pc_tab {
        margin: 0;
      }
    }
    @include pc_tab {
      &:not(:nth-child(1)):not(:nth-child(2)) {
        margin: 20px 0 0;
      }
    }
  }

  &__img {
    max-width: 100%;
  }
}

.member {
  margin: 20px 0 0;
  padding: 35px 15px 20px;
  background: $white;
  border: dashed 1px $color_1;
  border-radius: 5px;
  @include pc {
    width: 900px;
    margin: 0 auto;
    padding: 20px 50px;
  }

  &__ttl {
    padding: 18px;
    background: #FEDB0C;
    border-radius: 5px;
    text-align: center;
    font-size: 1.6em;
    font-weight: bold;
    position: relative;
    @include pc {
      padding: 13px;
      font-size: 2em;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &:before {
      content: '会員専用';
      display: inline-block;
      padding: 5px 30px;
      color: $white;
      background: $color_9;
      border-radius: 5px;
      position: absolute;
      top: 0;
      left: 50%;
      margin: 0 auto;
      transform: translate(-50%, -50%);
      @include pc {
        margin: 0 10px 0 0;
        padding: 4px 35px;
        position: static;
        transform: translate(0, 0);
        font-size: 1.6rem;
      }
    }
  }

  &__cinema {
    margin: 15px 0 0;
    display: flex;
    align-items: center;
    @include pc {
      margin: 20px 0 0;
    }
  }

  &__img {
    max-width: 100%;
    flex-shrink: 0;
  }

  &__cinema-txt {
    margin: 0 0 0 15px;
    font-size: 1.4em;
    line-height: 1.5;
    letter-spacing: 0.08em;
    font-weight: bold;
    @include pc {
      font-size: 1.8em;
      margin: 0 0 0 20px;
    }
  }

  &__txt {
    margin: 10px 0 0;
    font-style: 1.4em;
    line-height: 1.5;
    @include pc {
      font-size: 1.6em;
      line-height: 1.7;
      letter-spacing: 0.08em;
    }
  }

  &__btn {
    margin: 20px 0 0;
    @include pc {
      text-align: center;
    }
  }
}

._red {
  color: $color_9;
  margin: 20px 0 0;
}

.bnr_03 {
  @include sp_tab {
    width: 100%;
  }

  &__txt-wrap {
    background: $white;
  }
}

.bnr-lbl_01 {
  padding: 5px 8px;
}

.c_tag_1 {
  &__link {
    transition: 0.3s ease;

    &:hover {
      opacity: 0.7;
    }
  }
}

.banner {
  transition: 0.3s ease;

  &:hover {
    opacity: 0.7;
  }
}

.banner-list {
  &__banner {
    transition: 0.3s ease;

    &:hover {
      opacity: 0.7;
    }
  }
}

.bnr_03 {
  &__img-wrap {
    .h100 {
      height: 100%;
    }
  }
}

.is-news {
  height: 31px;
}

.mt_3 {
  margin-top: 3px;
}

.bnr_05pt2__tag-wrap {
  align-items: flex-start;
}

.look_map {
  background: url("../img/common/arrow_light_green.png") no-repeat left center;
  padding-left: 25px;
  background-size: 15px;
  color: #fff;
  @include sp {
    color: #707070;
    padding: 0;
    text-decoration: underline;
  }

  &:hover {
    text-decoration: underline;
  }
}
//header

.top-header-h1 {
  width: 100%;
  position: absolute;
  text-align: center;
  font-size: 12px;
  color: #fff;
  top: 5px;
  z-index: 600;
  @include sp {
    top: 0;
    line-height: 30px;
    background: #5A5249;
    font-size: 10px;
  }
}

.l_container {
  @include sp {
    //padding-top: 100px;
  }
}

.l_header.is-header {
  @include sp {
    top: 30px;
  }
}

.slider.banner01 {
  justify-content: center;
}

.img_txt_08 {
  @include mq_max(1024) {
    padding: 0 15px;
    margin: 0;
  }

  .img_txt_08__cont-wrap {
    box-shadow: 0 5px 40px #0000001a;
    @include mq_max(1024) {
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    img {
      margin: 0;
    }

    .bottom {
      background: $white;
      padding: 25px 20px 30px;
      @include mq_max(1024) {
        padding: 15px 15px 20px;
      }

      &.gastroscopy {
        background: url("../img/p_top/icon_stomach.svg") #fff no-repeat top 10px right 10px;
        @include mq_max(1024) {
          background: #fff;

          .p_text_04 {
            padding-left: 50px;
            position: relative;

            &::after {
              content: url("../img/p_top/sp/icon_stomach_sp.svg");
              position: absolute;
              left: 0;
              top: 0;
            }
          }
        }
      }

      &.colonoscopy {
        background: url("../img/p_top/icon_Intestine.svg") #fff no-repeat top 10px right 10px;
        @include mq_max(1024) {
          background: #fff;

          .p_text_04 {
            padding-left: 50px;
            position: relative;

            &::after {
              content: url("../img/p_top/sp/icon_Intestine_sp.svg");
              position: absolute;
              left: 0;
              top: 0;
            }
          }
        }
      }

      .pd01 {
        margin-bottom: 80px;
        @include mq_max(1024) {
          margin-bottom: 20px;
        }
      }

      .pd02 {
        margin-bottom: 20px;
      }

      .p_text_04 {
        margin-bottom: 10px;
        @include mq_max(1024) {
          margin-bottom: 15px;
        }
      }

      .p_text_01 {
        margin-bottom: 20px;
        @include mq_max(1024) {
          margin-bottom: 15px;
        }
      }

      .btn_01 {
        display: flex;
        justify-content: center;
        @include mq_max(1024) {
          display: block;
        }
      }
    }
  }
}

.sec-17 {
  margin-bottom: 50px;
  @include sp_tab {
    margin-bottom: 30px;
  }
}

.btn01 {
  margin: 0 auto 70px;
  max-width: 1100px;
  width: 100%;
  @include sp_tab {
    margin: 0 auto;
  }

  .inner {
    @include sp_tab {
      margin: 0 auto;
      padding: 0 15px;
    }

    ul {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include sp_tab {
        display: block;
      }

      li {
        max-width: 540px;
        @include sp_tab {
          max-width: 100%;
          margin-bottom: 15px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        a {
          display: block;

          &:hover {
            opacity: 0.7;
            transition: 0.3s ease;
          }
        }

        img {
          max-width: 100%;
        }
      }
    }
  }

  &.center {
    ul {
      justify-content: center;
    }
  }

  &.mg30 {
    margin: 0 30px 0 0;
    @include sp_tab {
      margin: 0;
    }
  }

  &.w525 {
    ul {
      li {
        max-width: 525px;
        @include sp_tab {
          max-width: 100%;
        }
      }
    }
  }

  &.w534 {
    ul {
      li {
        max-width: 534px;
        @include sp_tab {
          max-width: 100%;
        }
      }
    }
  }
}

.btn02 {
  margin: 0 auto 70px;
  max-width: 1100px;
  width: 100%;
  @include sp_tab {
    margin: 0 auto;
  }

  .inner {
    @include sp_tab {
      margin: 0 auto;
      padding: 0 15px;
    }

    ul {
      display: flex;
      justify-content: center;
      align-items: center;
      @include sp_tab {
        display: block;
      }

      li {
        max-width: 540px;
        @include sp_tab {
          max-width: 100%;
        }

        a {
          display: block;

          &:hover {
            opacity: 0.7;
            transition: 0.3s ease;
          }
        }

        img {
          max-width: 100%;
        }
      }
    }
  }
}

.doctor01 {
  max-width: 1100px;
  margin: 75px auto 55px;
  @include sp_tab {
    padding: 0 15px;
    margin: 50px auto 30px;
  }

  p {
    text-align: center;
    color: #845F3D;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin-bottom: -118px;
    z-index: 1;
    position: relative;
    @include sp_tab {
      font-size: 18px;
      padding: 0 15px;
      margin-bottom: -100px;
    }
  }

  .btn_01 {
    display: flex;
    justify-content: center;
    margin-top: -32px;
    @include sp_tab {
      margin-top: 0;
      width: 100%;
    }

    .btn_01__inner {
      padding: 20px 100px;
      height: auto;
      @include sp_tab {
        width: 100%;
      }
    }
  }
}

.c_conv_01.short {
  max-width: 1100px;
  margin: 0 auto 70px;
}

body.no-webp {
  .l_main_visual_top {
    .slider_mv {
      .img {
        &.lazyloaded._pt1 {
          background: url("../img/p_top/sp/mv_1.jpg") no-repeat center center/cover;
          @include pc_tab {
            background: url("../img/p_top/mv_1.jpg") no-repeat center center/cover;
          }
        }
      }
    }
  }

  .pc-sec-1.lazyloaded {
    background: url("../img/p_top/bg_cli.png") no-repeat center center/cover;
  }
}

.rect {
  width: 320px;
  height: 320px;
}

.wide {
  width: 100%;
  height: 320px;
}

.arrow {
  padding-top: 32px;
  padding-bottom: 32px;
  font-size: 24px;
}

.title {
  margin-top: 1000px;
  padding-bottom: 16px;
  font-size: 24px;
}

.w100 {
  @include sp {
    width: 100%;
    height: 100%;
  }
}

.btn03 {
  margin: 0 auto 70px;
  max-width: 1100px;
  width: 100%;
  @include sp_tab {
    margin: 0 auto;
  }

  .inner {
    @include sp_tab {
      margin: 0 auto;
      padding: 0 15px;
    }

    ul {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      @include sp_tab {
        display: block;
      }

      li {
        max-width: 540px;
        margin-bottom: 20px;
        @include sp_tab {
          max-width: 100%;
          margin-bottom: 15px;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &:nth-last-child(2) {
          margin-bottom: 0;
          @include sp_tab {
            margin-bottom: 15px;
          }
        }

        &:nth-child(odd) {
          margin-right: 20px;
          @include sp_tab {
            margin-right: 0;
          }
        }

        a {
          display: block;

          &:hover {
            opacity: 0.7;
            transition: 0.3s ease;
          }
        }

        img {
          max-width: 100%;
        }
      }
    }
  }
}
//
//
//
//
//
//
//
//
//